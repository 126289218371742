export default [
    {
        label: "Portál",
        to: "/portal",
    },
    {
        label: "Mapový portál",
        to: "/mapovy-portal",
    },
    {
        label: "Informace o DMVS",
        to: "/informace-o-dmvs",
    },
];
