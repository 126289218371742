import { useLogger } from "@agportal/logger";
import { createRestClient } from "@dmvs/client";
import { strict as assert } from "assert";
import { isEmpty, isNil } from "lodash-es";

/**
 * Service to offer access methods for subjects registration.
 */
export default class RegistraceSubjektuService {
    constructor(url) {
        assert(url, "url has to be specified");

        this.logger = useLogger();
        this.rest = createRestClient({
            baseURL: url,
        });
    }

    async registraceInfoFo() {
        const { data } = await this.rest.post("/fo/info");

        this.logger.debug("RegistraceSubjektuService.registraceInfoFo returns {0}", data);

        return data;
    }

    async registraceInfoPo(ico) {
        const payload = isNil(ico) ? {} : { ico };
        const { data } = await this.rest.post("/po/info", payload);

        this.logger.debug("RegistraceSubjektuService.registraceInfoPo for ico {0} returns {1}", ico, data);

        return data;
    }

    async registraceInfoUozi(cisloOpravneni) {
        if (!isNil(cisloOpravneni)) {
            const { data } = await this.rest.post("/fo/uozi/info", { cisloOpravneni });

            this.logger.debug(
                "RegistraceSubjektuService.registraceInfoUozi for authorization number {0} returns {1}",
                cisloOpravneni,
                data
            );

            return data;
        }
    }

    async registrujFo(subjekt) {
        if (!isEmpty(subjekt)) {
            const { data } = await this.rest.post("/fo/registruj", subjekt);

            this.logger.debug("RegistraceSubjektuService.registrujFo for subject {0} returns {1}", subjekt, data);

            return data;
        }
    }

    async registrujPo(subjekt) {
        if (!isEmpty(subjekt)) {
            const { data } = await this.rest.post("/po/registruj", subjekt);

            this.logger.debug("RegistraceSubjektuService.registrujPo for subject {0} returns {1}", subjekt, data);

            return data;
        }
    }
}
