<template>
    <Application
        title="Portál DMVS"
        :top-menu="topMenu"
        :menu="sideMenuToDisplay"
        :footer-links-left="footerLinks"
        :footer-links-right="footerIcons"
    >
        <template #notice>
            <PrimeMessage v-if="!!notice" :closable="true" severity="warn" class="my-0">
                <span v-markdown.html="{ value: notice, customizer: customizeLinks }" />
            </PrimeMessage>
        </template>
    </Application>
</template>

<script>
import { useConfig } from "@agportal/config";
import { useInjectionContext } from "@agportal/injection";
import { useLogger } from "@agportal/logger";
import { defineComponent } from "@agportal/util/components";
import Application from "@dmvs/components/layout/dmvsapplication";
import { loadUserDetails } from "@dmvs/composables/security";
import { customizeLinks } from "@dmvs/utils/common";
import { startsWith } from "lodash-es";
import { merge } from "lodash-es";
import PrimeMessage from "primevue/message";
import { computed, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";

import { clientConfigDefaults } from "./config/clientConfig";
import footerIcons from "./menu/footer-icons";
import footerLinks from "./menu/footer-links";
import sideMenu from "./menu/side-menu";
import topMenu from "./menu/top-menu";

export default defineComponent({
    components: {
        Application,
        PrimeMessage,
    },
    setup() {
        const logger = useLogger();
        const route = useRoute();
        const config = useConfig();
        const injection = useInjectionContext();

        const { configService, gadService, weboveObsahyService } = injection.get(
            "configService",
            "gadService",
            "weboveObsahyService"
        );

        const notice = ref(null);
        const sideMenuToDisplay = computed(() => {
            if (startsWith(route.path, "/portal")) {
                return sideMenu;
            } else {
                return null;
            }
        });

        const loadConfig = async () => {
            try {
                config.clientConfig = clientConfigDefaults;
                const data = await configService.vratKonfiguraci();
                config.clientConfig = merge({}, config.clientConfig, data);
            } catch (error) {
                logger.error("Došlo k chybě při načítání konfigurace aplikace.", error);
            }
        };

        const loadNotice = async () => {
            try {
                const data = await weboveObsahyService.ctiObsah("upozorneni");
                notice.value = data?.obsah;
            } catch (error) {
                logger.error("Došlo k chybě při načítání upozornění.", error);
            }
        };

        const loadUser = async () => {
            try {
                const subjekty = await gadService.vylistujMojeSubjektyGad({
                    from: 0,
                    size: 0,
                    getTotal: true,
                });
                return {
                    uozi: !!subjekty.total,
                };
            } catch (error) {
                logger.error("Došlo k chybě při ztotožňování AZI.", error);
            }
        };

        loadUserDetails(loadUser);

        onBeforeMount(() => {
            loadConfig();
            loadNotice();
        });

        return {
            topMenu,
            sideMenuToDisplay,
            footerIcons,
            footerLinks,
            notice,
            customizeLinks,
        };
    },
});
</script>
