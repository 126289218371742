import { useLogger } from "@agportal/logger";
import { createRestClient } from "@dmvs/client";
import { strict as assert } from "assert";
import { isEmpty } from "lodash-es";

export default class AziService {
    constructor(url) {
        assert(url, "url has to be specified");

        this.logger = useLogger();
        this.rest = createRestClient({
            baseURL: url,
        });
    }

    async vylistujAzi(query) {
        if (!isEmpty(query)) {
            const { data } = await this.rest.post(`/query`, query);

            this.logger.debug("AziService.vylistujAzi for query {0} returns {1}", query, data);

            return data;
        }

        return null;
    }
}
