import { useLogger } from "@agportal/logger";
import { createRestClient } from "@dmvs/client";
import { strict as assert } from "assert";
import { isNil } from "lodash-es";

export default class WeboveObsahyService {
    constructor(url) {
        assert(url, "url has to be specified");

        this.logger = useLogger();
        this.rest = createRestClient({ baseURL: url }, { secured: false });
    }

    async ctiObsah(key) {
        if (!isNil(key)) {
            const { data } = await this.rest.get(`/obsah/${key}`);
            this.logger.debug("WeboveObsahyService.ctiObsah for key {0} returns {1}", key, data);
            return data;
        }

        return null;
    }
}

