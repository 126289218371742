export const clientConfigDefaults = {
    vydejDat: {
        zadost: {
            prilohaMaxSize: 104857600,
        },
        datoveSady: {
            maxPocetMoMc: 100,
            maxPocetObci: 100,
            stavove: {
                datumOd: {
                    mesiceLimit: 3,
                },
            },
            zmenove: {
                datumOd: {
                    mesiceLimit: 2,
                },
            },
        },
        vyhotoveniDatoveSady: {
            maxArea: 1000000,
        },
    },
    openData: {
        datoveSady: {
            stavove: {
                datumOd: {
                    mesiceLimit: 3,
                },
            },
            zmenove: {
                datumOd: {
                    mesiceLimit: 2,
                },
            },
            request: {
                maxPocetObci: 100,
                maxPocetMoMc: 100,
            },
        },
    },
    prijemDat: {
        prijemGad: {
            gadMaxSize: 104857600,
        },
        validace: {
            jvfMaxSize: 104857600,
        },
    },
    evidence: {
        castiDti: {
            podklady: {
                maxVelikost: 2097152,
            },
        },
    },
    zjisteniUzemiSpravcuDti: {
        maxArea: 50000,
    },
    request: {
        geometryInputRestBodySizeLimit: 300000
    },
    datumSpusteni: "2023-01-01",
};
