import { useLogger } from "@agportal/logger";
import { createRestClient } from "@dmvs/client";
import { strict as assert } from "assert";
import { isEmpty, isNil } from "lodash-es";

/**
 * Service to offer access methods for subject founders.
 */
export default class ZjisteniUzemiSpravcuService {
    constructor(url) {
        assert(url, "url has to be specified");

        this.logger = useLogger();
        this.rest = createRestClient({
            baseURL: url,
        });
    }

    async posliPozadavek(pozadavek) {
        if (!isEmpty(pozadavek)) {
            const { data } = await this.rest.post("", pozadavek);

            this.logger.debug(
                "ZjisteniUzemiSpravcuService.posliPozadavek for request {0} returns {1}",
                pozadavek,
                data
            );

            return data;
        }

        return null;
    }

    async ctiPozadavek(idPozadavku) {
        if (!isNil(idPozadavku)) {
            const { data } = await this.rest.get(`/pozadavky/${idPozadavku}`);

            this.logger.debug(
                "ZjisteniUzemiSpravcuService.ctiPozadavek for id {0} returns {1}",
                idPozadavku,
                data
            );

            return data;
        }

        return null;
    }

    async vylistujZjisteneCastiDti(idPozadavku, query) {
        if (!isNil(idPozadavku)) {
            const { data } = await this.rest.post(`/pozadavky/${idPozadavku}/casti-dti/query`, query);

            this.logger.debug(
                "ZjisteniUzemiSpravcuService.vylistujZjisteneCastiDti for id {0} and query {1} returns {2}",
                idPozadavku,
                query,
                data
            );

            return data;
        }

        return null;
    }

    async vytvorReport(idPozadavku) {
        if (!isNil(idPozadavku)) {
            const { data } = await this.rest.post(`/pozadavky/${idPozadavku}/report`, {}, {
                responseType: "blob",
            });

            this.logger.debug(
                "ZjisteniUzemiSpravcuService.vytvorReport for id {0} returns {1}",
                idPozadavku,
                data
            );

            return new Blob([data], {
                type: data.type,
            });
        }

        return null;
    }
}
