import { useLogger } from "@agportal/logger";
import { localDate } from "@agportal/util/joda";
import { createRestClient } from "@dmvs/client";
import { strict as assert } from "assert";
import { isEmpty, isNil } from "lodash-es";

export default class VydejDatovychSadService {
    constructor(url) {
        assert(url, "url has to be specified");

        this.logger = useLogger();
        this.rest = createRestClient({
            baseURL: url,
        });
    }

    async vydejVerejneStavoveDatoveSady(payload) {
        if (!isEmpty(payload)) {
            const { data } = await this.rest.post("/datove-sady/verejne/stavove", payload);

            this.logger.debug(
                "VydejDatovychSadService.vydejVerejneStavoveDatoveSady for {0} returns {1}",
                payload,
                data
            );

            return data;
        }
    }

    async vydejVerejneZmenoveDatoveSady(payload) {
        if (!isEmpty(payload)) {
            const { data } = await this.rest.post("/datove-sady/verejne/zmenove", payload);

            this.logger.debug(
                "VydejDatovychSadService.vydejVerejneZmenoveDatoveSady for {0} returns {1}",
                payload,
                data
            );

            return data;
        }
    }

    async vydejNeverejneStavoveDatoveSady(payload) {
        if (!isEmpty(payload)) {
            const { data } = await this.rest.post("/datove-sady/neverejne/stavove", payload);

            this.logger.debug(
                "VydejDatovychSadService.vydejNeverejneStavoveDatoveSady for {0} returns {1}",
                payload,
                data
            );

            return data;
        }
    }

    async vydejNeverejneZmenoveDatoveSady(payload) {
        if (!isEmpty(payload)) {
            const { data } = await this.rest.post("/datove-sady/neverejne/zmenove", payload);

            this.logger.debug(
                "VydejDatovychSadService.vydejNeverejneZmenoveDatoveSady for {0} returns {1}",
                payload,
                data
            );

            return data;
        }
    }

    async vylistujSubjektyVydejeDat(query) {
        if (!isEmpty(query)) {
            const { data } = await this.rest.post("/subjekty/moje/query", query);

            this.logger.debug("VydejDatovychSadService.vylistujSubjektyVydejeDat for {0} returns {1}", query, data);

            return data;
        }
    }

    async nactiDetailSubjektuVydejeDat(idSubjektu) {
        if (!isNil(idSubjektu)) {
            const { data } = await this.rest.get(`/subjekty/by-id/${idSubjektu}/detail`);

            this.logger.debug(
                "VydejDatovychSadService.nactiDetailSubjektuVydejeDat for {0} returns {1}",
                idSubjektu,
                data
            );

            return data;
        }
    }

    async vylistujZadosti(idSubjektu, query) {
        if (!isNil(idSubjektu) && !isEmpty(query)) {
            const { data } = await this.rest.post(`/zadosti/by-subjekt/${idSubjektu}/query`, query);

            this.logger.debug(
                "VydejDatovychSadService.vylistujZadosti for id {0} and query {1} returns {2}",
                idSubjektu,
                query,
                data
            );

            return data;
        }
    }

    async ctiStavVydejNeverejneStavoveDatoveSady(id) {
        if (!isNil(id)) {
            const { data } = await this.rest.get(`/datove-sady/neverejne/stavove/podani/by-uuid/${id}`);

            this.logger.debug(
                "VydejDatovychSadService.ctiStavVydejNeverejneStavoveDatoveSady for id {0} returns {1}",
                id,
                data
            );

            return data;
        }
    }

    async ctiStavVydejNeverejneZmenoveDatoveSady(id) {
        if (!isNil(id)) {
            const { data } = await this.rest.get(`/datove-sady/neverejne/zmenove/podani/by-uuid/${id}`);

            this.logger.debug(
                "VydejDatovychSadService.ctiStavVydejNeverejneZmenoveDatoveSady for id {0} returns {1}",
                id,
                data
            );

            return data;
        }
    }

    async ctiStavVyhotovVerejnouDatovouSadu(id) {
        if (!isNil(id)) {
            const { data } = await this.rest.get(`/vyhotoveni-datovych-sad/verejne/podani/by-uuid/${id}`);

            this.logger.debug(
                "VydejDatovychSadService.ctiStavVyhotovVerejnouDatovouSadu for id {0} returns {1}",
                id,
                data
            );

            return data;
        }
    }

    async ctiStavVyhotovNeverejnouDatovouSadu(id) {
        if (!isNil(id)) {
            const { data } = await this.rest.get(`/vyhotoveni-datovych-sad/neverejne/podani/by-uuid/${id}`);

            this.logger.debug(
                "VydejDatovychSadService.ctiStavVyhotovNeverejnouDatovouSadu for id {0} returns {1}",
                id,
                data
            );

            return data;
        }
    }

    async vyhotovVerejnouDatovouSadu(payload) {
        if (!isEmpty(payload)) {
            const { data } = await this.rest.post("/vyhotoveni-datovych-sad/verejne", payload);

            this.logger.debug("VydejDatovychSadService.vyhotovVerejnouDatovouSadu for {0} returns {1}", payload, data);

            return data;
        }
    }

    async vyhotovNeverejnouDatovouSadu(payload) {
        if (!isEmpty(payload)) {
            const { data } = await this.rest.post("/vyhotoveni-datovych-sad/neverejne", payload);

            this.logger.debug(
                "VydejDatovychSadService.vyhotovNeverejnouDatovouSadu for {0} returns {1}",
                payload,
                data
            );

            return data;
        }
    }

    async ctiStavVydejVlastniDataVsp(id) {
        if (!isNil(id)) {
            const { data } = await this.rest.get(`/vlastni-data/vsp/podani/by-uuid/${id}`);

            this.logger.debug("VydejDatovychSadService.ctiStavVydejVlastniDataVsp for id {0} returns {1}", id, data);

            return data;
        }
    }

    async ztotozniSubjektVydejeDat() {
        const { data } = await this.rest.get("/subjekty/ztotozneni");

        this.logger.debug("SubjektyService.ztotozniSubjektVydejeDat returns {0}", data);

        return data;
    }

    async stahniSeznamVerejnychDatovychSad(idPozadavku, idSeznamu, datumVyhotoveni) {
        if (!isNil(idPozadavku)) {
            const zakladniUrl = `datove-sady/verejne/by-uuid/${idPozadavku}/seznam-sad`;
            let datumUrl = null;
            let seznamUrl = null;

            if (datumVyhotoveni) {
                datumUrl = `datumVyhotoveni=${localDate(datumVyhotoveni).value.toString()}`;
            }

            if (idSeznamu) {
                seznamUrl = `idSeznamu=${idSeznamu}`;
            }

            const url = datumVyhotoveni ? idSeznamu ? `${zakladniUrl}?${datumUrl}&${seznamUrl}` : `${zakladniUrl}?${datumUrl}` : idSeznamu ? `${zakladniUrl}?${seznamUrl}` : `${zakladniUrl}`;

            const { data } = await this.rest.get(url);

            this.logger.debug("VydejDatovychSadService.stahniSeznamVerejnychDatovychSad for idPozadavku {0}, idSeznamu {1}, and datumVyhotoveni {2} returns {3}", idPozadavku, idSeznamu, datumVyhotoveni, data);

            return data;
        }

        return null;
    }

    async stahniSeznamNeverejnychStavovychDatovychSad(idPodani, idSeznamu, datumVyhotoveni) {
        if (!isNil(idPodani)) {
            const zakladniUrl = `datove-sady/neverejne/stavove/podani/by-uuid/${idPodani}/seznam-sad`;
            let datumUrl = null;
            let seznamUrl = null;

            if (datumVyhotoveni) {
                datumUrl = `datumVyhotoveni=${localDate(datumVyhotoveni).value.toString()}`;
            }

            if (idSeznamu) {
                seznamUrl = `idSeznamu=${idSeznamu}`;
            }

            const url = datumVyhotoveni ? idSeznamu ? `${zakladniUrl}?${datumUrl}&${seznamUrl}` : `${zakladniUrl}?${datumUrl}` : idSeznamu ? `${zakladniUrl}?${seznamUrl}` : `${zakladniUrl}`;

            const { data } = await this.rest.get(url);

            this.logger.debug("VydejDatovychSadService.stahniSeznamNeverejnychStavovychDatovychSad for idPodani {0}, idSeznamu {1}, and datumVyhotoveni {2} returns {3}", idPodani, idSeznamu, datumVyhotoveni, data);

            return data;
        }

        return null;
    }

    async stahniSeznamNeverejnychZmenovychDatovychSad(idPodani, idSeznamu, datumVyhotoveni) {
        if (!isNil(idPodani)) {
            const zakladniUrl = `datove-sady/neverejne/zmenove/podani/by-uuid/${idPodani}/seznam-sad`;
            let datumUrl = null;
            let seznamUrl = null;

            if (datumVyhotoveni) {
                datumUrl = `datumVyhotoveni=${localDate(datumVyhotoveni).value.toString()}`;
            }

            if (idSeznamu) {
                seznamUrl = `idSeznamu=${idSeznamu}`;
            }

            const url = datumVyhotoveni ? idSeznamu ? `${zakladniUrl}?${datumUrl}&${seznamUrl}` : `${zakladniUrl}?${datumUrl}` : idSeznamu ? `${zakladniUrl}?${seznamUrl}` : `${zakladniUrl}`;

            const { data } = await this.rest.get(url);

            this.logger.debug("VydejDatovychSadService.stahniSeznamNeverejnychZmenovychDatovychSad for idPodani {0}, idSeznamu {1}, and datumVyhotoveni {2} returns {3}", idPodani, idSeznamu, datumVyhotoveni, data);

            return data;
        }

        return null;
    }
}
