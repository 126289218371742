import { defineConfig } from "@agportal/config";
import common from "@dmvs/config";
import { merge } from "lodash-es";

export default defineConfig(
    merge({}, common, {
        router: {
            mode: "html",
        },

        endpoints: {
            logger: "${env.API_BASE_URL}/client-log",
            ruian: "${env.API_BASE_URL}/ruian",
            subjekty: "${env.API_BASE_URL}/subjekty",
            registraceSubjektu: "${env.API_BASE_URL}/subjekty/registrace",
            gad: "${env.API_BASE_URL}/gad",
            ciselniky: "${env.API_BASE_URL}/ciselniky",
            isDtmk: "${env.API_BASE_URL}/subjekty/isdtmk",
            docasneSoubory: "${env.API_BASE_URL}/docasne-soubory",
            provozniInformace: "${env.API_BASE_URL}/isdmvs/provozni-informace",
            jvf: "${env.API_BASE_URL}/jvf",
            ciselnikyDmvs: "${env.API_BASE_URL}/ciselniky-dmvs",
            vydejDat: "${env.API_BASE_URL}/vydej",
            certifikaty: "${env.API_BASE_URL}/isdmvs/certifikaty",
            wms: "${env.API_WMS_URL}",
            openData: "${env.API_BASE_URL}/open-data/",
            azi: "${env.API_BASE_URL}/seznam-uozi",
            validaceVstupnichSouboru: "${env.API_BASE_URL}/validace-jvf",
            mapovyProhlizec: "${env.API_BASE_URL}/mapovy-prohlizec",
            wfs: "${env.API_BASE_URL}/wfs",
            weboveObsahy: "${env.API_BASE_URL}/webove-obsahy",
            isDtmKraju: "${env.API_BASE_URL}/isdtmk",
            clientConfig: "${env.API_BASE_URL}/client-config",
            zjisteniUzemiSpravcu: "${env.API_BASE_URL}/dti/zjisteni-spravcu-dti",
        },

        security: {
            storageKey: "${app.name}-security",
        },

        idleMonitor: {
            duration: 15 * 60,
            reminder: 60,
        },
    })
);
