import { useConfig } from "@agportal/config";
import { createStore } from "@agportal/store";

const config = useConfig();
const debug = config.env.NODE_ENV !== "production";
const key = `${config.app.name}-data`;
const secret = `${config.app.name}-secret`;

export default createStore({
    debug,

    modules: {},

    persistent: {
        key,
        storage: window.sessionStorage,
        paths: [],
    },

    secured: {
        secret,
        encryptKeys: !debug,
    },
});
