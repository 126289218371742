import { useLogger } from "@agportal/logger";
import { createRestClient } from "@dmvs/client";
import { strict as assert } from "assert";

/**
 * Service to offer access methods for client configuration.
 */
export default class ConfigService {
    constructor(url) {
        assert(url, "url has to be specified");

        this.logger = useLogger();
        this.rest = createRestClient({ baseURL: url }, { secured: false });
    }

    async vratKonfiguraci() {
        const { data } = await this.rest.get("");
        this.logger.debug("ConfigService.vratKonfiguraci returns {0}", data);
        return data;
    }
}
