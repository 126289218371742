import { useLogger } from "@agportal/logger";
import { createRestClient } from "@dmvs/client";
import { strict as assert } from "assert";
import { isEmpty, isNil } from "lodash-es";

/**
 * Service to offer access methods for subjects' users.
 */
export default class UzivateleSubjektuService {
    constructor(url) {
        assert(url, "url has to be specified");

        this.logger = useLogger();
        this.rest = createRestClient({
            baseURL: url,
        });
    }

    async vylistujPozadavkyNaPovereniUzivatele(subjekt, query) {
        if (!isEmpty(query)) {
            const { data } = await this.rest.post(`/by-id/${subjekt}/uzivatele/povereni/pozadavky/query`, query);

            this.logger.debug(
                "UzivateleSubjektuService.vylistujPozadavkyNaPovereniUzivatele" +
                    " for subject {0} and query {1} returns {2}",
                subjekt,
                query,
                data
            );

            return data;
        }
    }

    async poverUzivatele(subjekt, zadost) {
        if (!isEmpty(zadost)) {
            const { data } = await this.rest.post(`/by-id/${subjekt}/uzivatele/povereni/pover`, zadost);

            this.logger.debug(
                "UzivateleSubjektuService.poverUzivatele" + " for subject {0} and request {1} returns {2}",
                subjekt,
                zadost,
                data
            );

            return data;
        }
    }

    async zrusPovereniUzivatele(subjekt, zadost) {
        if (!isEmpty(zadost)) {
            const { data } = await this.rest.post(`/by-id/${subjekt}/uzivatele/povereni/zrus`, zadost);

            this.logger.debug(
                "UzivateleSubjektuService.zrusPovereniUzivatele" + " for subject {0} and request {1} returns {2}",
                subjekt,
                zadost,
                data
            );

            return data;
        }
    }

    async ctiInformaceOPovereniUzivatele(token) {
        if (!isNil(token)) {
            const { data } = await this.rest.get("/uzivatel/povereni/info", {
                params: {
                    token,
                },
            });

            this.logger.debug(
                "UzivateleSubjektuService.ctiInformaceOPovereniUzivatele for token {0} returns {1}",
                token,
                data
            );

            return data;
        }
    }

    async odesliVyjadreniKPovereniUzivatele(vyjadreni) {
        if (!isEmpty(vyjadreni)) {
            const { data } = await this.rest.post("/uzivatel/povereni/vyjadreni", vyjadreni);

            this.logger.debug(
                "UzivateleSubjektuService.odesliVyjadreniKPovereniUzivatele for statement {0} returns {1}",
                vyjadreni,
                data
            );

            return data;
        }
    }

    async zrusPozadavekNaPovereniUzivatele(subjekt, pozadavek) {
        if (!isNil(subjekt) && !isNil(pozadavek)) {
            const { data } = await this.rest.post(`/by-id/${subjekt}/uzivatele/povereni/pozadavky/by-id/${pozadavek}/storno`);

            this.logger.debug(
                "UzivateleSubjektuService.zrusPozadavekNaPovereniUzivatele" + " for subject {0} and request {1} returns {2}",
                subjekt,
                pozadavek,
                data
            );

            return data;
        }
    }

    async nactiMozneTypyPovereni(subjekt) {
        if (!isNil(subjekt)) {
            const { data } = await this.rest.get(`/by-id/${subjekt}/uzivatele/povereni/mozna-opravneni`);

            this.logger.debug(
                "UzivateleSubjektuService.nactiMozneTypyPovereni for subjekt {0} returns {1}",
                subjekt,
                data
            );

            return data;
        }
    }
}
