export default [
    {
        label: "Prohlášení o přístupnosti",
        to: "/prohlaseni-o-pristupnosti",
    },
    {
        label: "GDPR",
        to: "https://cuzk.gov.cz/Je-dobre-vedet/Ochrana-osobnich-udaju/Zpracovani-osobnich-udaju-provadene-CUZK.aspx",
        target: "_blank",
    },
];
