import "@dmvs/assets/cuzk/styles/theme/portal.scss";
import "@dmvs/assets/gov/scripts/cookie-consent.min.js";

import PortalVue from "@agportal/boot";
import FormatValue from "@agportal/directive/formatvalue";
import Markdown from "@agportal/directive/markdown";
import Sticky from "@agportal/directive/sticky";
import Joda from "@dmvs/utils/joda";
import OpenLayers from "@openlayers";
import Badge from "primevue/badgedirective";
import PrimeVue from "primevue/config";
import Ripple from "primevue/ripple";
import StyleClass from "primevue/styleclass";
import Tooltip from "primevue/tooltip";
import { createApp } from "vue";

import App from "@/App";
import InjectionContext from "@/injection";
import Locale from "@/locale";
import Router from "@/router";
import Store from "@/store";

const app = createApp(App)
    .use(PrimeVue, { ripple: true, inputStyle: "filled" })
    .use(PortalVue, { eventBus: true })
    .use(InjectionContext)
    .use(Locale)
    .use(Router)
    .use(Store)
    .use(OpenLayers)
    .use(Joda)
    .directive("styleclass", StyleClass)
    .directive("ripple", Ripple)
    .directive("tooltip", Tooltip)
    .directive("badge", Badge)
    .directive("sticky", Sticky)
    .directive("format", FormatValue)
    .directive("markdown", Markdown);

Router.isReady().then(() => {
    app.mount("#app");
});
