import { useLogger } from "@agportal/logger";
import { createRestClient } from "@dmvs/client";
import { strict as assert } from "assert";
import { isEmpty, isNil } from "lodash-es";

/**
 * Service to offer access methods for subject founders.
 */
export default class ValidaceVstupnichSouboruService {
    constructor(url) {
        assert(url, "url has to be specified");

        this.logger = useLogger();
        this.rest = createRestClient({
            baseURL: url,
        });
    }

    async validujJvf(subjekt, query) {
        if (!isNil(subjekt) && !isEmpty(query)) {
            const { data } = await this.rest.post(`/subjekty/${subjekt}/validuj`, query);

            this.logger.debug(
                "ValidaceVstupnichSouboruService.validujJvf for subject {0} and query {1} returns {2}",
                subjekt,
                query,
                data
            );

            return data;
        }

        return null;
    }

    async vylistujMojeSubjekty(query) {
        if (!isEmpty(query)) {
            const { data } = await this.rest.post("/subjekty/moje/query", query);

            this.logger.debug("ValidaceVstupnichSouboruService.vylistujMojeSubjekty", data);

            return data;
        }

        return null;
    }

    async vylistujPozadavkyNaValidaciSouboru(subjekt, query) {
        if (!isNil(subjekt) && !isEmpty(query)) {
            const { data } = await this.rest.post(`/subjekty/${subjekt}/pozadavky/query`, query);

            this.logger.debug(
                "ValidaceVstupnichSouboruService.vylistujPozadavkyNaValidaciSouboru for subject {0} and query {1} returns {2}",
                subjekt,
                query,
                data
            );

            return data;
        }

        return null;
    }

    async ctiPozadavekNaValidaciJvf(subjekt, idHlavicky) {
        if (!isNil(subjekt) && !isNil(idHlavicky)) {
            const { data } = await this.rest.get(`/subjekty/${subjekt}/pozadavky/by-hlavicka-id/${idHlavicky}`);

            this.logger.debug(
                "ValidaceVstupnichSouboruService.ctiPozadavekNaValidaciJvf for subject {0} and query {1} returns {2}",
                subjekt,
                idHlavicky,
                data
            );

            return data;
        }

        return null;
    }
}
