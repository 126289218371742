import { useLogger } from "@agportal/logger";
import { createRestClient } from "@dmvs/client";
import { strict as assert } from "assert";
import { isEmpty, isNil } from "lodash-es";

/**
 * Service to offer access methods for subject founders.
 */
export default class ZakladateleSubjektuService {
    constructor(url) {
        assert(url, "url has to be specified");

        this.logger = useLogger();
        this.rest = createRestClient({
            baseURL: url,
        });
    }

    async priradZakladatele(subjekt, query) {
        if (!isNil(subjekt) && !isEmpty(query)) {
            const { data } = await this.rest.post(`/dti/by-id/${subjekt}/zakladatele/pozadavky/prirazeni/prirad`, query);

            this.logger.debug(
                "ZakladateleSubjektuService.priradZakladatele for subject {0} and query {1} returns {2}",
                subjekt,
                query,
                data
            );

            return data;
        }
    }

    async odeberZakladatele(subjekt, zakladatel) {
        if (!isNil(subjekt) && !isNil(zakladatel)) {
            const { data } = await this.rest.delete(`/dti/by-id/${subjekt}/zakladatele/by-id/${zakladatel}`);

            this.logger.debug(
                "ZakladateleSubjektuService.odeberZakladatele for subject {0} and zakladatel {1} returns {2}",
                subjekt,
                zakladatel,
                data
            );

            return data;
        }
    }

    async ctiInformaceOPrirazeniZakladatele(token) {
        if (!isNil(token)) {
            const { data } = await this.rest.get("/dti/zakladatel/povereni/pozadavky/info", {
                params: {
                    token,
                },
            });

            this.logger.debug(
                "ZakladateleSubjektuService.ctiInformaceOPrirazeniZakladatele for token {0} returns {1}",
                token,
                data
            );

            return data;
        }
    }

    async odesliVyjadreniKPrirazeniZakladatele(vyjadreni) {
        if (!isEmpty(vyjadreni)) {
            const { data } = await this.rest.post("/dti/zakladatel/povereni/pozadavky/vyjadreni", vyjadreni);

            this.logger.debug(
                "ZakladateleSubjektuService.odesliVyjadreniKPrirazeniZakladatele for statement {0} returns {1}",
                vyjadreni,
                data
            );

            return data;
        }
    }

    async vylistujPozadavkyNaPovereniZakladatele(subjekt, query) {
        if (!isEmpty(query)) {
            const { data } = await this.rest.post(`/dti/by-id/${subjekt}/zakladatele/pozadavky/prirazeni/query`, query);

            this.logger.debug(
                "ZakladateleSubjektuService.vylistujPozadavkyNaPovereniZakladatele" +
                " for subject {0} and query {1} returns {2}",
                subjekt,
                query,
                data
            );

            return data;
        }
    }

    async vylistujPozadavkyNaPovereniZakladateleProSubjekty(subjekt, query) {
        if (!isEmpty(query) && !isNil(subjekt)) {
            const { data } = await this.rest.post(`/dti/zakladatel/povereni/pozadavky/by-subjekt-id/${subjekt}/query`, query);

            this.logger.debug(
                "ZakladateleSubjektuService.vylistujPozadavkyNaPovereniZakladateleProSubjekty" +
                " for subject {0} and query {1} returns {2}",
                subjekt,
                query,
                data
            );

            return data;
        }
    }

    async zrusPozadavekNaPrirazeniZakladatele(subjekt, pozadavek) {
        if (!isNil(subjekt) && !isNil(pozadavek)) {
            const { data } = await this.rest.post(`/dti/by-id/${subjekt}/zakladatele/pozadavky/prirazeni/by-id/${pozadavek}/storno`);

            this.logger.debug(
                "ZakladateleSubjektuService.zrusPozadavekNaPrirazeniZakladatele for subject {0} and request {1} returns {2}",
                subjekt,
                pozadavek,
                data
            );

            return data;
        }
    }
}
