import { useLogger } from "@agportal/logger";
import { createRestClient } from "@dmvs/client";
import { strict as assert } from "assert";

/**
 * Service to offer access methods for subject founders.
 */
export default class IsDtmKrajuService {
    constructor(url) {
        assert(url, "url has to be specified");

        this.logger = useLogger();
        this.rest = createRestClient({
            baseURL: url,
        });
    }

    async ctiStavKraju() {
        const { data } = await this.rest.get("/stav");

        this.logger.debug(
            "IsDtmKrajuService.ctiStavKraju returns {0}",
            data
        );

        return data;
    }
}
