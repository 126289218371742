import { useLogger } from "@agportal/logger";
import { createRestClient } from "@dmvs/client";
import { strict as assert } from "assert";
import { isEmpty } from "lodash-es";

export default class OpenDataService {
    constructor(url) {
        assert(url, "url has to be specified");

        this.logger = useLogger();
        this.rest = createRestClient({
            baseURL: url,
        });
    }

    async vylistujIsDtmk() {
        const { data } = await this.rest.get("/datove-sady/is-dtmk/info");

        this.logger.debug(
            "OpenDataService.vylistujIsDtmk returns {0}", data);

        return data;
    }

    async vylistujMoMc(query) {
        if (!isEmpty(query)) {
            const { data } = await this.rest.post("/datove-sady/momc/query", query);

            this.logger.debug("OpenDataService.vylistujMoMc for query {0} returns {1}", query, data);

            return data;
        }

        return null;
    }

    async vylistujObce(query) {
        if (!isEmpty(query)) {
            const { data } = await this.rest.post("/datove-sady/obce/query", query);

            this.logger.debug("OpenDataService.vylistujObce for query {0} returns {1}", query, data);

            return data;
        }

        return null;
    }

    async vylistujStavoveDatoveSady(query) {
        if (!isEmpty(query)) {
            const { data } = await this.rest.post("/datove-sady/stavove/info", query);

            this.logger.debug("OpenDataService.vylistujStavoveDatoveSady for query {0} returns {1}", query, data);

            return data;
        }

        return null;
    }

    async vylistujZmenoveDatoveSady(query) {
        if (!isEmpty(query)) {
            const { data } = await this.rest.post("/datove-sady/zmenove/info", query);

            this.logger.debug("OpenDataService.vylistujZmenoveDatoveSady for query {0} returns {1}", query, data);

            return data;
        }

        return null;
    }

    async stahniSeznamUrlStavoveDatoveSady(payload) {
        if (!isEmpty(payload)) {
            const { data } = await this.rest.post("/datove-sady/stavove/seznam-url", payload);

            this.logger.debug("OpenDataService.stahniSeznamUrlStavoveDatoveSady for {0} returns {1}", payload);

            return data;
        }

        return null;
    }

    async stahniSeznamUrlZmenoveDatoveSady(payload) {
        if (!isEmpty(payload)) {
            const { data } = await this.rest.post("/datove-sady/zmenove/seznam-url", payload);

            this.logger.debug("OpenDataService.stahniSeznamUrlZmenoveDatoveSady for {0} returns {1}", payload);

            return data;
        }

        return null;
    }
}
