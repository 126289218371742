export default [
    {
        label: "Registrace",
        to: "/portal/registrace",
    },
    {
        label: "Správa subjektu",
        to: "/portal/sprava-subjektu",
    },
    {
        label: "Části DTI",
        to: "/portal/casti-dti",
    },
    {
        label: "Rozsahy editace DTI",
        to: "/portal/rozsahy-editace-dti",
    },
    {
        label: "GAD DTM",
        to: "/portal/gad",
    },
    {
        label: "Výdej dat",
        to: "/portal/vydej-dat",
    },
    {
        label: "Zjištění území správců DTI",
        to: "/portal/uzemi-spravcu-dti",
    },
    {
        label: "JVF DTM",
        to: "/portal/jvf",
    },
    {
        label: "Přehledy a číselníky",
        to: "/portal/prehledy-ciselniky",
    },
    {
        label: "Informace o systému",
        to: "/portal/informace-o-systemu",
    },
    {
        label: "Stav IS DTM krajů",
        to: "/portal/stav-is-dtm-kraju",
    },
];
